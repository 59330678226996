import {ref,reactive,toRefs, computed,defineComponent,onBeforeMount,onMounted,onActivated,onDeactivated,nextTick,getCurrentInstance} from 'vue';
import MapPointUtil,{IMapPointDataObj} from "@/views/sysviews/map/mapPoint/mapPointUtil";
import car from '../../../../assets/car.png';
export default defineComponent ({
    name: "CarAddress",
    props: {
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IMapPointDataObj=reactive<IMapPointDataObj>({
            utilInst:{} as any,
            carPng: car,
            coordinate:[],
            markerMap:new Map(),
            markerInfo:[],//要在地图上标记marker的数组集合
            searchContent:'',
            mapInst: {},
            AMap:{} as any,
            geocoder:{} as any,
            searchAutoComplete:{} as any,
        })


        onBeforeMount(()=>{
            dataObj.utilInst=new MapPointUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async ()=>{
                (window as any).markerLabelClick=(options:any)=>{
                    if(!options)return;
                    dataObj.utilInst.markerLabelClick(options)
                }
                //请求后台，构造marker
                dataObj.markerInfo=[{
                    lng:116.219817,lat:40.23406,carId:11,cys:'重庆承运商不显示哦',orderNum:'1001',cx:'小货车',carNum:'187U6',driverName:'李四',
                    driverPhone:'11111',carAddress:'tianj',carSpeed:800
                },{
                    lng:119.52228,lat:39.888506,carId:12,cys:'重庆承运商不显示哦',orderNum:'10086',cx:'面包车',carNum:'LD807',driverName:'张三',
                    driverPhone:'2222',carAddress:'tianj',carSpeed:800
                }]
                await dataObj.utilInst.initMap({markerInfo:dataObj.markerInfo});
            })
        })


        const searchHandler=()=>{
            dataObj.utilInst.doSearch();
        }
        return{
            ...toRefs(dataObj),searchHandler
        }
    }
});